import { NextPage } from "next";
import dynamic from "next/dynamic";

// Dynamically import your component
const PurgePageContent = dynamic(
  () => import("@/ui/Pages/Purge/PurgePageContent"),
  {
    ssr: false, // disable server-side rendering for this component
  },
);

const Page: NextPage = () => {
  return (
    <>
      <PurgePageContent />
    </>
  );
};

export default Page;
